import React from "react";
import "./Ps.css";
import { Link } from "react-router-dom";

const products = [
  {
    name: "Passenger Steps",
    link: "/product/Ps/Pax",
    imgSrc: "/image/pax2.jpeg",
    description:
      "Passenger Steps, also known as aircraft boarding stairs or passenger boarding bridges, are portable staircases used to provide safe access for passengers between an aircraft and the ground at airports without jet bridges or when remote parking positions are used.",
  },
  // Add more products here if needed
];

function Ps() {
  return (
    <div className="product-container">
      <div className="product-box">
        <header className="product-header">
          <h1>PAX STEPS</h1>

          <p>
            PAX steps, short for passenger steps, are movable staircases used to
            bridge the gap between an aircraft door and the ground. They
            facilitate safe boarding and disembarkation of passengers,
            particularly in locations without fixed jet bridges.
          </p>
        </header>
        <div className="two-items">
          {products.map((product, index) => (
            <div className="product-item" key={index}>
              <Link to={product.link}>
                <h2>{product.name}</h2>
                <img src={product.imgSrc} alt={product.name} />
                <p>{product.description}</p>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Ps;
