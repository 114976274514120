import React from "react";
import "./Tat.css";
import { Link } from "react-router-dom";

const products = [
  {
    id: 1,
    name: "TMX450",
    image: "/image/tmx1.jpeg",
    description:
      "The TMX450 is a robust towbar aircraft tractor, facilitating efficient ground handling. It enhances operational safety and productivity at airports, designed for ease of use and reliability in various aircraft towing tasks.",
    link: "/product/TAT/TMX450",
  },
  {
    id: 2,
    name: "Towbarless",
    image: "/image/towbar3.jpeg",
    description:
      "A towbarless aircraft tractor eliminates the need for traditional towbars, streamlining ground operations by directly lifting and moving aircraft. It enhances efficiency and reduces turnaround times during aircraft handling.",
    link: "/product/TAT/Towbarless",
  },
];

function Tat() {
  return (
    <div className="product-container">
      <div className="product-box">
        <header className="product-header">
          <h1>TOWBARLESS AIRCRAFT TRACTORS</h1>
          <p>
            Towbarless aircraft tractors are specialized vehicles used for
            maneuvering airplanes without the need for a towbar. They provide
            efficient and safe towing operations, enhancing ground handling
            procedures at airports worldwide.
          </p>
        </header>
        <div className="two-items">
          {products.map((product) => (
            <div key={product.id} className="product-item">
              <Link to={product.link}>
                <h2>{product.name}</h2>
                <img src={product.image} alt={product.name} />
                <p>{product.description}</p>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Tat;
