import React from "react";
import "./Lavatory.css";
import { Link } from "react-router-dom";

const products = [
  {
    name: "Potable Water Truck",
    link: "/product/lavatory/Portable",
    imgSrc: "/image/potablewatertruck4.jpeg",
    description:
      "A Potable Water Truck is a specialized ground support vehicle used to supply clean and safe drinking water to aircraft. It ensures hygiene and comfort for passengers and crew during flights, equipped with high-pressure pumps and food-grade hoses.",
  },
  // Add more products here if needed
];

function Lavatory() {
  return (
    <div className="product-container">
      <div className="product-box">
        <header className="product-header">
          <h1>Lavatory and Water Trucks</h1>
          <p>
            Lavatory and Water Trucks are essential ground support vehicles for
            aircraft. They service lavatories and supply potable water, ensuring
            passenger comfort and operational efficiency at airports worldwide.
          </p>
        </header>
        <div className="two-items">
          {products.map((product, index) => (
            <div className="product-item" key={index}>
              <Link to={product.link}>
                <h2>{product.name}</h2>
                <img src={product.imgSrc} alt={product.name} />
                <p>{product.description}</p>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Lavatory;
