import React, { useState } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Button,
  Flex,
  Text,
} from "@chakra-ui/react";
import emailjs from "emailjs-com";
import "./Contact.css";

function ContactUs() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .send(
        "service_0va4ogp",
        "template_ykuk7jp",
        formData,
        "GH5Br7MEDuTfFzNsx"
      )
      .then((response) => {
        console.log("SUCCESS!", response.status, response.text);
      })
      .catch((err) => {
        console.log("FAILED...", err);
      });
  };

  return (
    <div style={{ background: "rgb(245,245,247)" }}>
      <div>
        <div className="headingContact">Contact Us</div>
        <Text mb={4} textAlign="center">
          If you have any questions, feel free to reach out to us!
        </Text>
      </div>
      <div>
        <Flex
          className="contact-container"
          p={4}
          maxWidth="1200px"
          mx="auto"
          flexDirection={{ base: "column", md: "row" }}
          gap={4}
        >
          <Box
            className="contact-form"
            flex={1}
            p={4}
            boxShadow="md"
            borderRadius="md"
            bg="white"
          >
            <Flex as="form" direction="column" onSubmit={handleSubmit}>
              <FormControl mb={4}>
                <FormLabel>Full Name</FormLabel>
                <Input
                  type="text"
                  name="name"
                  placeholder="Enter your name"
                  value={formData.name}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl mb={4}>
                <FormLabel>Email Address</FormLabel>
                <Input
                  type="email"
                  name="email"
                  placeholder="Enter your email address"
                  value={formData.email}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl mb={4}>
                <FormLabel>Phone Number</FormLabel>
                <Input
                  type="tel"
                  name="phone"
                  placeholder="Enter your phone number"
                  value={formData.phone}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl mb={4}>
                <FormLabel>Message</FormLabel>
                <Textarea
                  name="message"
                  placeholder="Enter your message"
                  value={formData.message}
                  onChange={handleChange}
                />
              </FormControl>
              <Button type="submit" className="submitButton">
                Submit
              </Button>
            </Flex>
          </Box>
          <Box className="contact-map" flex={1}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3532.2327373598773!2d85.31082247567326!3d27.71009952536974!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb1901a56aec5b%3A0x86e655f8065b6ba6!2sOPTO%20LENS!5e0!3m2!1sen!2snp!4v1718641688190!5m2!1sen!2snp"
              width="100%"
              height="450"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Google Map"
            ></iframe>
          </Box>
        </Flex>
      </div>
    </div>
  );
}

export default ContactUs;
