const images = [
  { imgURL: "image/slider1.jpeg", imgAlt: "img-1" },
  { imgURL: "image/slider2.jpeg", imgAlt: "img-2" },
  { imgURL: "image/slider3.jpeg", imgAlt: "img-3" },
  { imgURL: "image/slider4.jpeg", imgAlt: "img-4" },
  { imgURL: "image/slider5.jpeg", imgAlt: "img-5" },
  { imgURL: "image/slider6.jpeg", imgAlt: "img-6" },
  { imgURL: "image/slider7.jpeg", imgAlt: "img-7" },
  { imgURL: "image/slider8.jpeg", imgAlt: "img-8" },
];

export default images;
