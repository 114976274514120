import React from "react";
import "./RampBus.css";
import { Link } from "react-router-dom";

const rampBusProducts = [
  {
    id: 1,
    name: "COBUS Aircraft Ramp Bus",
    image: "/image/COBUS3.jpeg",
    description:
      "The COBUS Aircraft Ramp Bus is a specialized vehicle designed for transporting passengers between the terminal and aircraft. It ensures efficient boarding and disembarking processes, enhancing airport operational flow.",
    link: "/product/RampBus/COBUS",
  },
];

function RampBuss() {
  return (
    <div className="product-container">
      {rampBusProducts.map((product) => (
        <div key={product.id} className="product-item">
          <Link to={product.link}>
            <h2>{product.name}</h2>
            <img src={product.image} alt={product.name} />
            <p>{product.description}</p>
          </Link>
        </div>
      ))}
    </div>
  );
}

export default RampBuss;
