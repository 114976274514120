import React from "react";
import "./Partner.css";

const partners = [
  {
    name: "Cobus Industries",
    href: "https://www.cobus-industries.com",
    src: "https://www.cobus-industries.com/wp-content/uploads/2020/06/logo_cobus_new.jpg",
    alt: "Cobus Industries Logo",
  },
  {
    name: "TLD Group",
    href: "https://www.tld-group.com",
    src: "https://logowik.com/content/uploads/images/tld-group4172.logowik.com.webp",
    alt: "TLD Group Logo",
  },
  {
    name: "Resqtec",
    href: "https://resqtec.com",
    src: "https://www.fireproductsearch.com/wp-content/uploads/2017/06/Resqtec-logo.jpg",
    alt: "Resqtec",
  },
  {
    name: "BK Technologies",
    href: "https://www.bktechnologies.com",
    src: "https://www.bktechnologies.com/wp-content/uploads/2023/12/homebanner.jpg",
    alt: "BK Technologies",
  },
  // ... Add remaining partner data
];

export default function Partner() {
  return (
    <div className="partnerHeading">
      <h1 className="HeadPartners">Our Partners</h1>
      <div className="partner-grid">
        {partners.map((partner) => (
          <a key={partner.name} href={partner.href} className="partner-item">
            <img src={partner.src} alt={partner.alt} />
            <span className="partner-name">{partner.name}</span>
          </a>
        ))}
      </div>
    </div>
  );
}
