import React, { useState, useEffect } from "react";
import slider1 from "../../assets/slider1.jpg";
import slider2 from "../../assets/slider2.jpg";
import slider3 from "../../assets/slider3.jpg";
import slider4 from "../../assets/slider4.jpg";
import slider5 from "../../assets/slider5.jpg";
import slider6 from "../../assets/slider6.jpg";
import slider7 from "../../assets/slider7.jpg";
import slider8 from "../../assets/slider8.jpg";

const images = [
  { src: slider1, alt: "img-1" },
  { src: slider2, alt: "img-2" },
  { src: slider3, alt: "img-3" },
  { src: slider4, alt: "img-4" },
  { src: slider5, alt: "img-5" },

  { src: slider7, alt: "img-7" },
  { src: slider8, alt: "img-8" },
];

const Slider = () => {
  const [current, setCurrent] = useState(0);
  const [imageStyle, setImageStyle] = useState({
    width: "auto",
    height: "95vh",
  });

  const updateImageStyle = () => {
    const width = window.innerWidth;
    const height = window.innerHeight;

    if (width < 768) {
      setImageStyle({ width: "100%", height: "50vh" });
    } else if (width < 1024) {
      setImageStyle({ width: "100%", height: "75vh" });
    } else {
      setImageStyle({ width: "100%", height: "95vh" });
    }
  };

  useEffect(() => {
    updateImageStyle();
    window.addEventListener("resize", updateImageStyle);
    return () => window.removeEventListener("resize", updateImageStyle);
  }, []);

  const nextSlide = () => {
    setCurrent((prev) => (prev === images.length - 1 ? 0 : prev + 1));
  };

  const prevSlide = () => {
    setCurrent((prev) => (prev === 0 ? images.length - 1 : prev - 1));
  };

  useEffect(() => {
    const interval = setInterval(nextSlide, 3000); // Change slide every 3 seconds
    return () => clearInterval(interval); // Clear interval on component unmount
  }, []);

  return (
    <div className="slider">
      <button onClick={prevSlide}>←</button>
      <img
        src={images[current].src}
        alt={images[current].alt}
        style={imageStyle}
      />
      <button onClick={nextSlide}>➔</button>
    </div>
  );
};

export default Slider;
