import React from "react";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaedinIn,
  FaLinkedinIn,
} from "react-icons/fa";
import { NavLink } from "react-router-dom";
import "./Footer.css";

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section">
          <h3 className="footerHeader">Contact Us</h3>
          <p>Mater Trading Company Pvt. Ltd.</p>
          <p>Kathmandu, Nepal</p>
          <p>Phone: +977 9851081958</p>
          <p>
            Email: <a href="mtchub@gmail.com">mtchub@gmail.com</a>
          </p>
          <p>
            Corp Office: Nil Saraswati Street, Lazimpat 2, Kathmandu, Nepal
            44600
          </p>
          <p>Regd. Office: Jyatha 27, Kathmandu, Nepal</p>
        </div>
        <div className="footer-section">
          <h3 className="footerHeader">Quick Links</h3>
          <ul>
            <li>
              <NavLink to="/" activeClassName="active" className="footer-links">
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/about"
                activeClassName="active"
                className="footer-links"
              >
                About Us
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/product"
                activeClassName="active"
                className="footer-links"
              >
                Import/Products
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/contact"
                activeClassName="active"
                className="footer-links"
              >
                Contact Us
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="footer-section">
          <h3 className="footerHeader">Follow Us</h3>
          <ul className="social-media">
            <li>
              <a
                href="https://www.facebook.com/mtchub"
                className="social-link"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Facebook"
              >
                <FaFacebookF className="social-icon" />
                <span className="social-text">Facebook</span>
              </a>
            </li>
            {/* <li>
              <a
                href="https://twitter.com"
                className="social-link"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Twitter"
              >
                <FaTwitter className="social-icon" />
                <span className="social-text">Twitter</span>
              </a>
            </li>
            <li>
              <a
                href="https://instagram.com"
                className="social-link"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Instagram"
              >
                <FaInstagram className="social-icon" />
                <span className="social-text">Instagram</span>
              </a>
            </li> */}
            <li>
              <a
                href="https://www.linkedin.com/in/dipak-regmi-26043253/"
                className="social-link"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="LinkedIn"
              >
                <FaLinkedinIn className="social-icon" />
                <span className="social-text">LinkedIn</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <p>
          &copy; {new Date().getFullYear()} Mater Trading Company Pvt. Ltd. All
          rights reserved.
        </p>
      </div>
    </footer>
  );
}

export default Footer;
