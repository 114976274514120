import React from "react";

const Gpu = () => {
  return (
    <div className="nbl-page">
      <header className="nbl-header">
        <h1 className="nbl-header-title">Ground Power Units(GPU)</h1>
      </header>

      <section className="nbl-details">
        <div className="nbl-images">
          <div className="nbl-image-row">
            <div className="nbl-image-container">
              <img src="\image\GPU.jpeg" alt="COBUS" className="GPU-image" />
            </div>
          </div>
        </div>
        <div className="nbl-description">
          <div className="nbl-content">
            <p>
              GROUND POWER UNITS (GPUs) manufactured are advanced,
              high-performance devices designed to provide electrical power to
              aircraft on the ground. GPUs come in various models to cater to
              different aircraft needs, ranging from small regional jets to
              large commercial airliners. These units are known for their
              reliability, efficiency, and environmental friendliness, utilizing
              state-of-the-art technology to ensure stable and consistent power
              output. GPUs are equipped with user-friendly interfaces and robust
              safety features, making them easy to operate and maintain. They
              are available in both diesel and electric versions, reflecting
              commitment to reducing the aviation industry's carbon footprint.
            </p>
            <br />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Gpu;
