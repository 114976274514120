import React from "react";
import "./Bl.css";
import { Link } from "react-router-dom";

const products = [
  {
    name: "NBL",
    link: "/product/BL/NBL",
    imgSrc: "/image/Belt3.jpeg",
    description:
      "The NBL series, manufactured, consists of versatile belt loaders designed for efficient baggage and cargo handling in airport operations. These loaders feature an adjustable conveyor belt system that can easily reach aircraft cargo holds of varying heights, ensuring smooth and quick loading and unloading processes. Known for their robust construction, reliability, and user-friendly operation, the NBL belt loaders are equipped with advanced safety features to protect both the operator and the cargo.",
  },
  // Add more products here if needed
];

function Bl() {
  return (
    <div className="product-container">
      <div className="product-box">
        <header className="product-header">
          <h1>Belt Loaders</h1>

          <p>
            Belt loaders are ground support equipment used at airports to load
            and unload baggage and cargo from aircraft. They typically consist
            of a conveyor belt mounted on a vehicle chassis that can be adjusted
            in height to reach the cargo holds of different aircraft types.
          </p>
        </header>
        <div className="two-items">
          {products.map((product, index) => (
            <div className="product-item" key={index}>
              <Link to={product.link}>
                <h2>{product.name}</h2>
                <img src={product.imgSrc} alt={product.name} />
                <p>{product.description}</p>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Bl;
