import React from "react";
import "./Product.css";
import { Link } from "react-router-dom";

const products = [
  {
    path: "/product/TAT",
    title: "TOWBARLESS AIRCRAFT TRACTORS",
    img: "/image/towbar3.jpeg",
    description:
      "TOWBARLESS AIRCRAFT TRACTORS are advanced ground support equipment designed to efficiently handle aircraft towing operations without the need for a towbar. These tractors utilize a unique clamping mechanism to secure the aircraft's nose landing gear, allowing for rapid and safe movement of various aircraft sizes. Towbarless tractors offer enhanced maneuverability, reduced turnaround times, and improved safety by minimizing the risk of damage to the aircraft and ground equipment.",
  },
  {
    path: "/product/BL",
    title: "BELT LOADERS",
    img: "/image/Belt1.jpeg",
    description:
      "BELT LOADERS manufactured are essential ground support equipment used for the efficient loading and unloading of aircraft baggage and cargo. Designed for reliability and ease of use, these belt loaders feature adjustable conveyor belts that extend from the ground to the aircraft hold, facilitating the smooth transfer of luggage. Belt loaders, such as the NBL and RBL series, are known for their durability, ergonomic design, and advanced safety features, ensuring efficient operations while minimizing the risk of damage to baggage and aircraft.",
  },
  {
    path: "/product/PS",
    title: "Pax Steps",
    img: "/image/pax3.jpeg",
    description:
      "Pax Steps manufactured are high-quality passenger boarding stairs designed to facilitate safe and efficient boarding and disembarking of passengers from various aircraft types. These stairs are built with robust materials and offer adjustable height capabilities to accommodate different aircraft door heights. Pax Steps, such as the ABS and TS series, are known for their stability, ease of maneuverability, and user-friendly features, including non-slip surfaces and handrails.",
  },
  {
    path: "/product/lavatory",
    title: "LAVATORY AND WATER TRUCKS",
    img: "/image/potablewatertruck4.jpeg",
    description:
      "LAVATORY AND WATER TRUCKS manufactured are essential ground support vehicles designed to service aircraft lavatory systems and potable water supplies efficiently and hygienically. These trucks are built with durable tanks and reliable pumping systems to handle waste extraction and replenish fresh water. Lavatory and water trucks, such as the LSP and WSP series, are known for their robust construction, user-friendly operation, and compliance with stringent sanitary standards.",
  },
  {
    path: "/product/Rampbus",
    title: "Ramp Bus",
    img: "/image/COBUS6.jpeg",
    description:
      "The Ramp Bus manufactured is a specialized airport bus designed to transport passengers between the terminal and aircraft efficiently and comfortably. Known for its spacious interior and high passenger capacity, the COBUS Ramp Bus provides a smooth and reliable transit experience on the airport tarmac. Features include low-floor entry for easy boarding, large panoramic windows, and ergonomic seating. COBUS Ramp Buses are engineered for durability and reliability, with advanced safety features and efficient drive systems.",
  },
  {
    path: "/product/GPU",
    title: "GPU",
    img: "/image/GPU.jpeg",
    description:
      "Our diverse range of rugged Ground Power Units (GPU) have been designed to meet the requirements of the most power-sensitive aircraft. From 28V DC to 400hZ, TLD can offer high efficiency solutions – including our latest environmentally friendly lithium powered GPU-409-iBS model.",
  },
];

function Product() {
  return (
    <div className="product-containers">
      <div className="product-boxes">
        <header className="product-header">
          <h1>Our Imports/Products</h1>
          <p>
            Welcome to MTC. We are a Nepal-based company, dedicated to importing
            top-quality goods from China to Nepal. As the authorized seller, we
            ensure you get the best products at competitive prices.
          </p>
        </header>
        <div className="two-item">
          {products.map((product, index) => (
            <div className="products-item" key={index}>
              <Link to={product.path}>
                <h2>{product.title}</h2>
                <img src={product.img} alt={product.title} />
                <p>{product.description}</p>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Product;
