import React from "react";
import "./About.css";

const About = () => {
  return (
    <div className="about-container">
      <h1>About Us</h1>
      <div className="about-box">
        <p>
          Mater Trading Company Pvt Ltd specializes mainly in the import,
          participating in government tender, and trading business supply. We
          supply military vehicles/equipment, GSE equipment/aviation equipment,
          and telecommunication items and their related accessories. Our major
          clients include the Nepal Army, Armed Police Force (APF),
          International & Domestic Airports, Ground Handling Companies, Nepal
          Airlines, Telecom, Nepal Electricity Authority (NEA), and other
          government entities.
        </p>
        <br />
        <p>
          We maintain a staff of highly qualified engineers who operate under
          the leadership of a young and energetic management team.
        </p>
      </div>
    </div>
  );
};

export default About;
